<template>
  <div class="box-content">
    <div class="spinner" v-if="loading">
      <div class="line1"></div>
    </div>
    <div v-else>
      <h2>Modifier un service</h2>
      <div class="info_grid">
        <div class="pt-10">
          <text-input
            :errors="errors.service"
            v-model="form.service"
            label="Nom du service"
          />
        </div>
        <div class="pt-10">
          <label>Client</label>
          <v-select
            :options="customers"
            placeholder="Selectionner"
            v-model="form.customer_id"
            label="name"
            :reduce="(item) => item.id"
          ></v-select>
          <div
            v-if="errors.customer_id"
            :class="errors.customer_id.length > 0 ? 'invalid-feedback' : ''"
          >
            <strong class="has-text-danger" style="font-size: 12px">{{
              errors.customer_id[0]
            }}</strong>
          </div>
        </div>
        <div class="pt-10">
          <label>Facturation</label>
          <v-select
            :options="ratings"
            placeholder="Selectionner"
            v-model="form.rating"
            label="label"
            :reduce="(item) => item.id"
          ></v-select>
          <div
            v-if="errors.rating"
            :class="errors.rating.length > 0 ? 'invalid-feedback' : ''"
          >
            <strong class="has-text-danger" style="font-size: 12px">{{
              errors.rating[0]
            }}</strong>
          </div>
        </div>
        <div class="pt-10">
          <label>Permissions</label>
          <v-select
            :options="permissions"
            multiple
            placeholder="Selectionner"
            v-model="form.permissions"
            label="description"
            :reduce="(item) => item.id"
          ></v-select>
          <div
            v-if="errors.permissions"
            :class="errors.permissions.length > 0 ? 'invalid-feedback' : ''"
          >
            <strong class="has-text-danger" style="font-size: 12px">{{
              errors.permissions[0]
            }}</strong>
          </div>
        </div>
      </div>
      <div class="info_grid_t">
        <div class="pt-10">
          <label>Opérateurs</label>
          <v-select
            :options="operators"
            multiple
            placeholder="Selectionner"
            v-model="form.operator"
            label="bankcode"
            :reduce="(item) => item.id"
          ></v-select>
          <div
            v-if="errors.operator"
            :class="errors.operator.length > 0 ? 'invalid-feedback' : ''"
          >
            <strong class="has-text-danger" style="font-size: 12px">{{
              errors.operator[0]
            }}</strong>
          </div>
        </div>
        <div class="pt-10">
          <text-input
            v-model="form.description"
            label="Description du service"
          />
        </div>
      </div>
      <div class="pt-10">
        <label class="checkbox"
          ><input v-model="form.active" type="checkbox" /> Activer le
          service</label
        >
      </div>
      <div class="pt-10 d-flex-right">
        <button
          @click="updateService"
          :disabled="edit"
          :class="
            edit
              ? 'button is-responsive is-loading is-primary'
              : 'button is-responsive is-primary'
          "
        >
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput";
export default {
  name: "editService",
  components: { TextInput },
  data: () => ({
    loading: false,
    edit: false,
    errors: [],
    form: {
      id: 2,
      service: "",
      active: null,
      description: "",
      customer_id: "",
      rating: "",
      operator: [],
      permissions: [],
    },
  }),

  computed: {
    ...mapGetters({
      service: `${TYPES.modules.SERVICES}/${TYPES.getters.GET_SERVICE}`,
      customers: `${TYPES.modules.UTILS}/${TYPES.getters.GET_MCP}`,
      permissions: `${TYPES.modules.UTILS}/${TYPES.getters.GET_PERMISSIONS}`,
      ratings: `${TYPES.modules.UTILS}/${TYPES.getters.GET_RATING}`,
      operators: `${TYPES.modules.UTILS}/${TYPES.getters.GET_OPERATOR}`,
    }),
  },
  mounted() {
    this.getService();
    this.getCustomers();
    this.getPermissions();
    this.getRatings();
    this.getOperators();
  },

  methods: {
    getCustomers() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_MCP}`
      );
    },

    getPermissions() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_PERMISSIONS}`
      );
    },

    getRatings() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_RATING}`
      );
    },

    getOperators() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_OPERATOR}`
      );
    },

    getService() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SERVICES + "/" + TYPES.actions.DETAILS_SERVICE}`,
          this.$route.params.id
        )
        .then((res) => {
          if (res) {
            this.form = this.service;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    updateService() {
      this.edit = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SERVICES + "/" + TYPES.actions.UPDATE_SERVICE}`,
          this.form
        )
        .then((res) => {
          this.edit = false;
          this.$toast.success(`Le service a été mis à jour`);
          this.errors = [];
        })
        .catch((error) => {
          this.edit = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.info_grid_t {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
}
</style>